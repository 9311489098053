import React from 'react';

import '../../assets/sass/main.scss';

const Footer = () => (
  <footer className="footer">
    Daletra © 2021 by <a href="https://www.rubenprol.gal">Rubén Prol</a>.
  </footer>
);

export default Footer;
